<template>
  <svg
    width="10"
    height="6"
    viewBox="0 0 10 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.12891 5.26562L1.08203 1.24609C0.945312 1.13672 0.945312 0.917969 1.08203 0.78125L1.62891 0.261719C1.76562 0.125 1.95703 0.125 2.09375 0.261719L5.375 3.48828L8.62891 0.261719C8.76562 0.125 8.98438 0.125 9.09375 0.261719L9.64062 0.78125C9.77734 0.917969 9.77734 1.13672 9.64062 1.24609L5.59375 5.26562C5.45703 5.40234 5.26562 5.40234 5.12891 5.26562Z"
      fill="currentColor"
    />
  </svg>
</template>
